import React, { useState } from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import loadComponents from '../Loadable';

const H2 = loadComponents('h2');
const Items = loadComponents('product-category-items');
const Button = loadComponents('button');

export default function ProductCategory({ data }) {
  const [active, setActive] = useState(``);

  const selectItem = link => {
    setActive(link);
    window.scrollTo({ top: 9001, left: 0 });
  };

  return (
    <div>
      <Row justify="space-between">
        <Col xxl={{ width: '25%' }}>
          <H2>Choose what type of visa you require</H2>
        </Col>
        <Col className="visaSelection" xxl={{ width: '75%' }}>
          <ul className="visaTypes">
            {data.map((item, index) => (
              <Items
                key={item.node.databaseId}
                data={item.node}
                index={index}
                active={active}
                setActive={selectItem}
              />
            ))}
          </ul>
          <Button to={active} disabled={active === ``} primary>
            Continue
          </Button>
        </Col>
      </Row>
    </div>
  );
}
